<template>
  <v-row justify="center">
    <v-dialog :value="true" persistent max-width="480px" transition="dialog-transition" @input="$emit('close-dialog')">
      <v-card>
        <v-form>
          <v-card-title>
            <v-list-item class="pa-0">
              <v-list-item-content>
                <h3 class="mr-4" v-text="$t('team.importPlayers.title')" />
              </v-list-item-content>
              <v-spacer />
              <v-list-item-action>
                <v-btn icon color="black" @click="$emit('close-dialog')">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-title>
          <v-card-text class="mt-0">
            <!-- Download template -->
            <p class="mr-4" v-text="$t('team.importPlayers.subtitle')" />

            <!-- Step 1 -->
            <span class="font-weight-bold" v-text="'1  '" />
            <span v-text="$t('team.importPlayers.decriptionStep1')" />
            <div class="mb-8 mt-4 pl-4">
              <v-btn color="primary" outlined depressed width="100%" @click.prevent="downloadCsv" v-text="$t('common.downloadCsv')" />
            </div>

            <!-- Step 2 -->
            <span class="font-weight-bold" v-text="'2  '" />
            <span v-text="$t('team.importPlayers.decriptionStep2')" />
            <div class="mt-4 pl-4">
              <!-- Imput -->
              <v-file-input v-model="form.file" :label="$t('common.uploadCsv')" :prepend-icon="null" outlined dense>
                <template #prepend-inner>
                  <v-icon color="primary" v-text="'file_upload'" />
                </template>
              </v-file-input>
            </div>
            <div class="mb-8 pl-4">
              <v-btn color="primary" class="w-100" depressed @click="parseFileToJson(form.file)" v-text="'Import'" />
            </div>
            <div class="mb-8 pl-4">
              <p
                v-for="error in errors" :key="error"
                :style="{ color: '#DF5555' }"
                type="error"
              >
                {{ error }}
              </p>
              <p class="mb-4" v-text="$t('team.importPlayers.decriptionFooter')" />
            </div>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import lodash from 'lodash'
import { DateTime } from 'luxon'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'ImportPlayersFromCsv',
  mixins: [formUtils],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    cohortId: { type: String, required: true },
    teamId: { type: String, required: true },
    playerList: { type: Array, required: true },
  },
  data() {
    return {
      form: {
        file: null,
      },
      fileErrors: [],
      validationErrors: [],
      rows: [],
      schema: {
        firstName: { isRequired: true, type: 'string', label: `${this.$t('user.fields.firstName')}*` },
        lastName: { isRequired: true, type: 'string', label: `${this.$t('user.fields.lastName')}*` },
        dni: { isRequired: false, type: 'string', label: `${this.$t('user.fields.numberIdentificationCsvDontChange')}` },
        birthdateYear: { isRequired: false, type: 'number', label: `${this.$t('user.fields.birthdate')}(YYYY)` },
        birthdateMonth: { isRequired: false, type: 'number', label: `${this.$t('user.fields.birthdate')}(MM)` },
        birthdateDay: { isRequired: false, type: 'number', label: `${this.$t('user.fields.birthdate')}(DD)` },
        number: { isRequired: true, type: 'number', label: `${this.$t('user.fields.numberPlayer')}*` },
        licenseCode: { isRequired: false, type: 'string', label: this.$t('user.fields.licenseCode') },
        email: { isRequired: false, type: 'email', label: this.$t('project.academy.users.competitorEmail') },
      },
      kitNumbers: [],
      emails: [],
      validationByType: {
        string: value => lodash.isString(value),
        datestring: value => DateTime.fromISO(value).isValid,
        number: value => lodash.isNumber(value),
        email: value => {
          const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return re.test(String(value).toLowerCase())
        },
      },
    }
  },
  computed: {
    ...mapGetters({ cohorts: 'competition/cohorts' }),
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters({ club: 'club/data' }),
    ...mapGetters({ team: 'club/team' }),
    cohort: ({ cohorts, cohortId }) => cohorts.find(cohort => cohort.id === cohortId),
    errors: ({ validationErrors, fileErrors }) => [...validationErrors, ...fileErrors],
  },

  validations() {
    return {
      form: {
        file: { required },
      },
    }
  },
  methods: {

    async parseFileToJson(file) {
      await this.validateFile(file)
      if (this.fileErrors.length) return

      await this.$papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: results => {
          if (JSON.stringify(results.data).includes('�')) {
            this.fileErrors.push(this.$t('team.importPlayers.validations.fileFormat'))
            return
          }

          if (results.errors.length) this.fileErrors.push(this.$t('team.importPlayers.validations.formatCells'))
          if (this.fileErrors.length) return

          this.rows = results.data.map(tableRow => Object.fromEntries(Object.entries(Object.entries(tableRow)).map(([i, [columnLabel, value]]) => ([
            Object.keys(this.schema)[i],
            this.schema[Object.keys(this.schema)[i]].type === 'number' ? +value : this.schema[Object.keys(this.schema)[i]].type === 'string' ? value.trim() : value,
          ]))))

          this.kitNumbers = this.playerList.map(e => e.number)
          this.emails = this.playerList.filter(p => p.email).map(p => p.email)
          this.validationErrors = []
          this.validateRows()
          this.save()
        },
      })
    },

    async validateFile(file) {
      this.fileErrors = []
      if (!file) this.fileErrors.push(this.$t('validation.fileNotFound'))
      if (file.type !== 'text/csv') this.fileErrors.push(this.$t('validation.fileCsv'))
    },

    validateRows() {
      const { schema, rows } = this
      for (const [index, row] of Object.entries(rows)) {
        for (const [field, { isRequired, type, label }] of Object.entries(schema)) {
          const value = row[field]

          // validate header of csv
          if (index === '0' && Object.keys(this.schema).join() !== Object.keys(row).join()) {
            this.validationErrors.push(this.$t('team.importPlayers.headersInvalid'))
            return
          }

          let isValid = true
          if (isRequired) {
            isValid = this.validationByType[type](value)
          } else {
            isValid = value ? this.validationByType[type](value) : true
          }

          if (!value && isRequired) {
            this.validationErrors.push(this.$t('team.importPlayers.requiredTypeError', [label, value, +index + 1]))
          } else if (!isValid) {
            this.validationErrors.push(this.$t('team.importPlayers.invalidTypeError', [label, value, +index + 1]))
          } else if (field === 'number') {
            const isValidKitNumber = !this.kitNumbers.includes(value)
            if (isValidKitNumber) {
              this.kitNumbers.push(value)
            } else {
              this.validationErrors.push(this.$t('team.importPlayers.kitNumberExists', [label, value, +index + 1]))
            }
          } else if (field === 'email') {
            const isValidEmail = !this.emails.includes(value)
            if (value && isValidEmail) {
              this.emails.push(value)
            } else if (value) {
              this.validationErrors.push(this.$t('team.importPlayers.emailExists', [label, value, +index + 1]))
            }
          }
        }
      }
    },
    async downloadCsv() {
      const csv = this.$papa.unparse([{
        [`${this.schema.firstName.label}`]: 'Example',
        [`${this.schema.lastName.label}`]: 'Example',
        [`${this.schema.dni.label}`]: 'Example123',
        [`${this.schema.birthdateYear.label}`]: '1994',
        [`${this.schema.birthdateMonth.label}`]: '10',
        [`${this.schema.birthdateDay.label}`]: '29',
        [`${this.schema.number.label}`]: 2,
        [`${this.schema.licenseCode.label}`]: '0321932',
        [`${this.schema.email.label}`]: 'example@test.com',
      }], { header: true, delimiter: ';' })

      // TODO: CRIS CSV Check for refactor
      const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      const csvURL = window.URL.createObjectURL(csvData)
      const testLink = document.createElement('a')
      testLink.href = csvURL
      const fileName = `${`${this.project.name}_${this.club.name}_${this.cohort.name}`}.csv`
      testLink.setAttribute('download', fileName)
      testLink.click()
    },
    async save() {
      const { organizationId, projectId, cohortId, teamId, rows } = this

      if (this.errors.length) return
      const rowsParsed = rows.map(row => ({
        ...row,
        birthdate: {
          year: +row.birthdateYear,
          month: +row.birthdateMonth,
          day: +row.birthdateDay,
        },
      }))
      this.runAsync(async () => {
        await Promise.all(rowsParsed.map(data => this.$store.dispatch('club/createPlayer', { organizationId, projectId, cohortId, teamId, data })))
        this.$store.dispatch('club/updateByClubmanager', { organizationId, projectId, updatedType: 'teamPlayers' })
        this.$emit('close-dialog')
      })
    },
  },
}
</script>
